<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6668 0H0.333496V10.6667C0.333496 15.8267 4.50683 20 9.66683 20C14.8268 20 19.0002 15.8267 19.0002 10.6667V9.33333H19.5335C21.7602 9.33333 23.7868 7.82667 24.2268 5.64C24.8535 2.64 22.5602 0 19.6668 0ZM16.3335 2.66667V6.66667H3.00016V2.66667H16.3335ZM19.6668 6.66667H19.0002V2.66667H19.6668C20.7735 2.66667 21.6668 3.56 21.6668 4.66667C21.6668 5.77333 20.7735 6.66667 19.6668 6.66667ZM0.333496 21.3333H21.6668V24H0.333496V21.3333Z"
      fill="#6E19F0"
    />
  </svg>
</template>

<script>
export default {
  name: 'ic-coffee',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped></style>
