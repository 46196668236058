import { render, staticRenderFns } from "./ic-coffee.vue?vue&type=template&id=73a96e83&scoped=true"
import script from "./ic-coffee.vue?vue&type=script&lang=js"
export * from "./ic-coffee.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73a96e83",
  null
  
)

export default component.exports